/* Main */
body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #434E5E;
    margin: 0;
    /*font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";*/
    /*--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";*/
    /*--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;*/
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    overflow-x: hidden;
}

/* Colors */
.color-dark-blue {
    color: #0a71c6;
}

.color-light-blue {
    color: #41A4F5;
}

.color-light-red {
    color: #e65d5d;
}

.color-light-green {
    color: #70c689;
}

.color-light-yellow {
    color: #c0c676;
}

.color-light-purple {
    color: #c67eb0;
}

.color-dark-grey {
    color: #434E5E;
}

.color-light-grey {
    color: rgba(146, 146, 148, 0.69);
}

.color-light-white {
    color: #F7F8FA;
}

.bg-color-dark-blue {
  background-color: #0a71c6;
}

.bg-color-light-blue {
  background-color: #41A4F5;
}

.bg-color-light-red {
    background-color: #e65d5d;
}

.bg-color-light-green {
    background-color: #70c689;
}

.bg-color-light-yellow {
    background-color: #c0c676;
}

.bg-color-light-purple {
    background-color: #c67eb0;
}

.bg-color-dark-grey {
    background-color: #434E5E;
}

.bg-color-light-grey {
    background-color: rgba(146, 146, 148, 0.69);
}

.bg-color-light-white {
  background-color: #F7F8FA;
}

/* Utils */
.rounded {
    border-radius: .25rem !important;
}

/* Navigation */
nav {
    box-shadow: 0 0px 30px rgba(0, 0, 0, 0.25);
}

.navbar-brand {
    margin-right: 0 !important;
    padding: 0 0px;
    height: 70px;
    line-height: 70px;
}

#logo img {
    height: 32px;
}

.navbar {
    min-height: 70px;
    padding: 0 1rem;
}

.navbar--hidden {
    background-color: transparent !important;
    box-shadow: 0 0 0;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.6);
}

.collapsing {
    -webkit-transition: none;
    transition: none;
}

#navigation {
    font-size: 0.875rem;
    text-align: center;
}

#navigation .active {
    font-weight: 600;
}

.dropdown-toggle {
    background: transparent !important;
    border-color: transparent !important;
}

#gamefolio {
    background: rgba(0,0,0,0.2);
    border: none;
    max-width: 140px;
}

/* Containers */
@media (max-width: 992px) {
    nav > .container {
        max-width: none !important;
    }
}

#canvas-about {
  width: 100%;
  background: #ffffff;
  position: relative;
  margin-top: 110px;
  display: inline-block;
  box-shadow: 0 0px 35px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

section {
  padding-bottom: 50px !important;
  padding-top: 50px !important;
  /*padding-top: 150px !important;*/
  /*margin-top: -100px !important;*/
}

#canvas-after-about {
    padding-top: 150px !important;
}

/* Common */
ul {
    padding: 0;
}

.link-on-bg, a.link-on-bg {
    color: rgba(0,0,0,0.5);
}

.visible {
    visibility: visible;
    -webkit-transform: translateY(0) scale(1);
    opacity: 1;
    transform: translateY(0) scale(1);
    -webkit-transition: -webkit-transform 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
    transition: transform 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 1.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
}

/* About */
#about {
    margin-bottom: -110px;
}

.profile-photo img {
    width: 100%;
    height: auto;
    /*display: block;*/
    /*border: 0;*/
    /*max-width: 100%;*/
    /*vertical-align: middle;*/
}

.profile-info {
    color: #3d4451;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #dedede;
}

.profile-hello {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin-bottom: 28px;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

.profile-hello span:before {
    content: '';
    width: 0;
    height: 0;
    top: 100%;
    left: 5px;
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 0 0 8px 8px;
    border-color: transparent;
    border-left-color: #41A4F5;
}

.profile-hello span {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    display: inline-block;
    padding: 7px 12px;
    text-transform: uppercase;
    position: relative;
}

.profile-title {
    font-size: 36px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 5px;
}

.profile-title span {
    font-weight: 300;
}

.profile-occupation {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: 0;
}

/* The flip card container */
.flip-card {
    /*-webkit-perspective: 1000px;*/
    /*perspective: 1000px;*/
}

.tricky {
    opacity: 0;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-transition: all 1.0s linear;
    transition: all 1.0s linear;
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /*-webkit-backface-visibility: hidden;*/
    /*backface-visibility: hidden;*/
}

.flip-card-front {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-animation: mymoveback 30s infinite;
    animation: mymoveback 30s infinite;
}

.flip-card-back {
    -webkit-animation: mymove 30s infinite;
    animation: mymove 30s infinite;
}

/* Flip card animation */
@-webkit-keyframes mymove {
    40% {
        -webkit-transform: rotateY(0deg);
    }
    50% {
        -webkit-transform: rotateY(180deg);
    }
    90% {
        -webkit-transform: rotateY(180deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
    }
}
@-webkit-keyframes mymoveback {
    40% {
        -webkit-transform: rotateY(-180deg);
    }
    50% {
        -webkit-transform: rotateY(0deg);
    }
    90% {
        -webkit-transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
    }
}

@keyframes mymove {
    40% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    90% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}
@keyframes mymoveback {
    40% {
        transform: rotateY(-180deg);
    }
    50% {
        transform: rotateY(0deg);
    }
    90% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(-180deg);
    }
}

#contact {
    margin: 30px 0 0 0;
    padding: 0;
    font-size: 30px;
}

#social {
    margin: 0;
}

#social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
}

#social li:first-child {
    margin-left: 0 !important;
}

#social li a {
    color: #41A4F5;
}

/* Career */
.vertical-timeline.vertical-timeline--two-columns {
    width: 100% !important;
}

.vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #fff,inset 0 2px 0 rgba(0,0,0,.08),0 3px 10px 4px rgba(0,0,0,.05) !important;
}

.vertical-timeline-element-content {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05) !important;
}

.vertical-timeline-element-subtitle {
    font-size: 95%;
}

.career-company-logo {
    max-height: 30px;
}

/* Skills */
.skills-block-inner {
    overflow: hidden;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15) !important;
}

.skills-list li:last-child {
    margin-bottom: 0 !important;
}

/* Projects */
#projects-tabs {
    background:none;
}

#projects-tabs > .nav-tabs {
    /*border: none;*/
    margin: 0;
}

#projects-tabs > .nav-tabs > .nav-item {
    margin-right: 2px;
}

#projects-tabs > .nav-tabs > .nav-item > a {
    border: 0;
    border-bottom:2px solid transparent;
    margin-right: 0;
    color: #3d4451;
    padding: 2px 15px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
}

#projects-tabs > .nav-tabs > .nav-item > a.active {
    background: none;
    border-bottom: 2px solid #41A4F5 !important;
    color: #41A4F5;
    font-weight: 700;
}

#projects-tabs > .nav-tabs > .nav-item > a:hover {
    border-bottom: 2px solid #41A4F5 !important;
    color: #41A4F5 !important;
}

.projects-box  {
    margin: 0 auto;
    padding: 20px;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.projects-box-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #41A4F5;
    opacity: 0.9;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: .4s ease;
}

/* Container needed to position the overlay. Adjust the width as needed */
.projects-box-wrapper {
    background-clip: content-box;
    position: relative;
    overflow: hidden;
    /*width: 50%;*/
}

.projects-box-wrapper img {
    transition: transform .4s; /* Animation */
}

.projects-box-wrapper:hover img {
    transform: scale(1.2); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* When you mouse over the container, the overlay text will "zoom" in display */
.projects-box-wrapper:hover .projects-box-overlay {
    transform: scale(1);
}

/* Some text inside the overlay, which is positioned in the middle vertically and horizontally */
.projects-box-title {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.projects-gallery > .slick-list > .slick-track > .slick-slide img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    opacity: 0.95;
}

#project-features > .list-group {
    max-width: 400px;
}

#project-features > .list-group > .list-group-item:first-child {
    border-top: 0 !important;
    padding-top: 1.5rem;
}

.badge {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    display: inline-block;
    padding: 7px 12px;
}

/* Hire */
#hire-image {
    width: 140px;
    height: 140px;
}

#hire-description {
    max-width: 780px;
}

/* Back to top */
.back-to-top {
    outline: 0 !important;
}

#topcontrol {
    background: #0a71c6;
    color: #fff;
    text-align: center;
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: .25rem;
    padding-top: 6px;
    font-weight: 300;
    position: fixed;
    bottom: 10px;
    right: 10px;
    opacity: 1;
    cursor: pointer;
}